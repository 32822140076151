import { useEffect } from 'react';
import React from "react";
import { Helmet } from 'react-helmet';

export default function Erp() {
  useEffect(() => {

    document.title = 'Customize ERP'
  });
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - Customized ERP Development" />
        <meta name="description" content="Optimize your business with Psoft’s ERP development solutions. Our customized ERP systems can streamline finance, HR, inventory, and more, providing a unified platform to enhance efficiency and growth." />
        <meta name="keywords" content="ERP development, customized ERP software, enterprise resource planning, business management software, ERP solutions, finance management, inventory control, HR software, streamline operations, Psoft ERP" />

      </Helmet>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>ERP Development</h2>
                </div>
                <p>
                Have a successful company but find it a headache to manage it? Now you can develop software to manage finance, inventory, HR, and more efficiently. Running a successful company is a remarkable achievement, but it often comes with the challenge of managing numerous aspects like financial management, inventory control, human resources, and operations. That's where our ERP (Enterprise Resource Planning) development services come in. We empower you to take control, streamline your operations, and scale your business efficiently with a tailored software solution.

<b>Why ERP Matters</b>
As your company grows, managing various functions becomes increasingly complex. ERP software acts as the nerve center of your organization, bringing together essential processes like financial management, inventory management, HR management, and project management into a unified system. It eliminates manual work, reduces errors, and allows you to make informed decisions to achieve sustainable growth and efficiency.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">Get Free consultaion</a>              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/erp-img.png" alt="erp image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
