import React from "react";
import { useEffect } from "react";

export default function ErpProject(){
    useEffect(() => {
        document.title = "Web Based ERP System"; 
      });
return(
<div>
    <div class="container single-project">
        <div class="row my-5 g-5">
            <div class="col-lg-8 my-4">
                <h2 class="mb-4">Web Based ERP System</h2>
                <img src="../images/WhatsApp Image 2024-12-04 at 18.46.57_45fa7743.jpg" class="img-fluid project-img"
                    alt="project-img" />
                <p class="mt-5">This Laravel-based web-based ERP system also aims to streamline the business processes
                    by improving their existing system. We handle both the front-end and
                    back-end. The system can boast responsiveness, ensuring seamless operation across various devices,
                    while its diverse functionalities cater to a range of business needs.
                </p>
                <div class="row my-5">
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/WhatsApp Image 2024-12-04 at 18.47.53_23b6ec4d.jpg" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/WhatsApp Image 2024-12-04 at 18.49.03_2cfd5466.jpg" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                </div>

            </div>
            <div class="col-lg-4 my-4 px-5">
                <div class="row flex-column">
                    <div class="col-12">
                        <h3>Category</h3>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">Web App Development</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Website</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Custom Software</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">ERP System</span>
                        </div>
                    </div>
                    <div class="col-12 mt-5 tech">
                        <h3>Technologies</h3>
                        <h5 class="my-3">Front-End</h5>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">HTML5</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">CSS3</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">JavaScript</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">JQuery</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Boostrap</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Select2</span>
                        </div>
                        <h5 class="my-3">Back-End</h5>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">PHP</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Laravel</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">MySQL</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}