import { useEffect } from 'react';
import React from "react";
import { Helmet } from 'react-helmet';

export default function Web() {
  const height = {
    height: "338px",
  };
  useEffect(() => {

    document.title = 'Web Application'
  })

  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - Business Website Development" />
        <meta name="description" content="Elevate your business with a fully functional, converting website from Psoft. Boost your online presence, enhance sales potential, and make a lasting impression with our business website solutions." />
        <meta name="keywords" content="business website, website development, online presence, sales potential, Psoft, digital storefront, website for business growth" />
      </Helmet>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>Business Website</h2>
                </div>
                <p>
                A fully functional website that helps you enhance your business and increase your sales potential. In today's digital age, a robust online presence is the cornerstone of success for any business. We are dedicated to crafting a fully functional website that not only enhances your business but significantly elevates your sales potential.

<b>Why a Business Website Matters</b>
In a world where customers turn to the internet for information and services, your business website serves as your digital storefront. It's more than just a virtual business card; it's a dynamic platform that can drive growth, foster credibility, and facilitate interactions with your audience. With features like responsive design, SEO optimization, and user-friendly navigation, we ensure your website meets the needs of your customers and supports your business goals.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">Get Free consultaion</a>              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/web-img.png" alt="website image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
