import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function Projects() {
  useEffect(() => {
    document.title = "Projects"; 
  });
  return (
    <div>
     <Helmet>
    <meta property="title" content="Psoft - Showcase of Successful Software Projects" />
    <meta name="description" content="Explore Psoft's project portfolio to see how our tailored software solutions drive business growth. Discover our expertise in web, ERP, and e-commerce projects." />
    <meta name="keywords" content="Psoft project portfolio, software development, business website projects, ERP systems, e-commerce solutions, SEO optimization, custom software, digital transformation" />
</Helmet>

      <section class="py-5">
        <div class="container mb-5">
          <div class="text-center mb-5">
            <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Our Projects</span></h1>
          </div>
          <div class="row gx-5 justify-content-center">
            <div class="col-lg-11 col-xl-9 col-xxl-8">
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Japan Direct (Car Auction Website)</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Auction Website.jpeg"
                          alt="Japan Direct (Car Auction Website)" height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="JapanDirect">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Margarita Shipping (Transport Website)</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Margarita Shipping (Transport Website).jpeg"
                          alt="Margarita Shipping (Transport Website)" height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="Transworld">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Custome Web Based ERP</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/WhatsApp Image 2024-12-04 at 18.46.57_45fa7743.jpg"
                          alt="Custome Web Based ERP" height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="erp">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Software House Website</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Software House Website.jpeg"
                          alt="Software House Website" height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="CodianWeb">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Water Supply Management System</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Water Supply Management System.png"
                          alt="Water Supply Management System" height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="WaterMange">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}