// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomLoadingBar from "./CustomLoadingBar";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services";
import Contact from "./Components/Contact";
import Web from "./Components/Services/Web";
import E_commerce from "./Components/Services/E_commerce";
import Seo from "./Components/Services/Seo";
import Erp from "./Components/Services/Erp";
import Single_blog from "./Components/blog/Single_blog";
import Blogs from "./Components/blog/Blogs";
import Projects from "./Components/Projects";

import ErpProject from "./Components/projects/Erp";
import CodianWebProject from "./Components/projects/CodianWeb";
import JapanDirectProject from "./Components/projects/JapanDirect";
import TransworldProject from "./Components/projects/Transworld";
import WaterMangeProject from "./Components/projects/WaterMange";
import ErrorBoundary from './ErrorBoundary';


function App() {
  return (
    <ErrorBoundary>

      <Router scrollToTop={true} basename="/">
        <div>
          <Header />

          <CustomLoadingBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/aboutFill" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/projects" element={<Projects />} />

            <Route path="/projects/Erp" element={<ErpProject />} />
            <Route path="/projects/CodianWeb" element={<CodianWebProject />} />
            <Route path="/projects/JapanDirect" element={<JapanDirectProject />} />
            <Route path="/projects/Transworld" element={<TransworldProject />} />
            <Route path="/projects/WaterMange" element={<WaterMangeProject />} />
            <Route path="/projects" element={<Projects />} />

            <Route path="/blogs/:title/:id" element={<Single_blog />} />

            <Route path="/blogs" element={<Blogs />} />

            <Route path="/services/web-application" element={<Web />} />
            <Route path="/services/seo" element={<Seo />} />
            <Route path="/services/e-commerce" element={<E_commerce />} />
            <Route path="/services/erp" element={<Erp />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
