import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Initialize the error state
    this.state = { hasError: false, errorMessage: '' };
  }

  // Method that updates the state when an error is caught
  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  // Optional: Log the error details for debugging (e.g., to an external service)
  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error);
    console.error('Error details:', errorInfo);
    // You could send the error details to a logging service here
  }

  // Render a fallback UI when an error is caught
  render() {
    if (this.state.hasError) {
      return <h2>Something went wrong. Please try again later.</h2>;
    }
    return this.props.children; // Render children if no error occurred
  }
}

export default ErrorBoundary;
