import React from "react";
import { useEffect } from "react";

export default function CodianWebProject(){
    useEffect(() => {
        document.title = "Software House Website"; 
      });
return(
<div>
    <div class="container single-project">
        <div class="row my-5 g-5">
            <div class="col-lg-8 my-4">
                <h2 class="mb-4">Software House Website</h2>
                <img src="../images/Software House Website.jpeg" class="img-fluid project-img"
                    alt="project-img" />
                <p class="mt-5"></p>
                <div class="row my-5">
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/WhatsApp Image 2024-07-09 at 12.50.20 AM.jpeg" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/WhatsApp Image 2024-07-09 at 12.48.18 AM.jpeg" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                </div>

            </div>
            <div class="col-lg-4 my-4 px-5">
                <div class="row flex-column">
                    <div class="col-12">
                        <h3>Category</h3>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">Web App Development</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Website</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Custom Web App</span>
                        </div>
                    </div>
                    <div class="col-12 mt-5 tech">
                        <h3>Technologies</h3>
                        <h5 class="my-3">Front-End</h5>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">HTML5</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">CSS3</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">JavaScript</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Boostrap</span>
                        </div>
                        <h5 class="my-3">Back-End</h5>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">PHP</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Laravel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}